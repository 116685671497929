<template>
  <div class="my-second info bgcolor">
      <Head :title="$t('info.address')" :show="true" :right="true"  >
            <span class="head-success" @click="addAddress">{{$t('info.add_ads')}}</span>
      </Head>
      <div class="common-box info-box" v-if="show">
            <div class="address-list" v-if="addrlist.length>0">
                <van-swipe-cell v-for="(l,i) in addrlist" :key="i">
                    <div class="address-list-item" >
                        <div class="address-list-item-left" @click.stop="chooseAddress(l)">
                            <div class="address-list-item-left-top">
                                <p class="username">{{l.realname}}</p>
                                <p class="phone">{{l.mobile}}</p>
                            </div>
                            <div class="address-list-item-left-bottom">
                                <p class="meo" v-if="l.isdefault==1">{{$t('info.moren')}}</p>
                                <p class="area" :class="i==0?'width180':''">
                                    {{l.city+" " + l.address}} 
                                </p>
                            </div>
                        </div>
                        <van-button class="address-list-item-right" @click.stop="resetAddress(l)">
                            {{$t("info.aduit")}}
                        </van-button>
                    </div>
                    <template #right>
                        <van-button  :text="$t('info.detele')" @click="delOneAddr(l)" class="delete-button" />
                    </template>
                </van-swipe-cell>
                
            </div>
            <!-- 地址为空 -->
            <div  v-else class="no-box" >
                <img class="juan-no" src="../../../assets/images/no-address.png" alt="">
                <p class="no-text">{{$t('info.no_address')}}</p>
            </div>

        <!-- 确认邮寄 -->
        <van-overlay class="g-overlay " :show="repay"  @click="repay = false" >
          <div class="repay-mask address-sure" @click.stop>
                <div class="top">
                    {{$t('info.sure_address')}}
                </div>
                <div class="title">{{$t('info.sure_address_tip')}}</div>
                <div class="overlay-btns">
                    <van-button class="surebtn btn-sure"  @click.stop="youjiwinning()">{{$t('login.sure')}}</van-button>
                </div>
          </div>
        </van-overlay>
      </div>
  </div>
</template>

<script>
import {mapState,mapMutations} from "vuex"
import {randomString} from "@/common"
import {Notify} from "vant"
export default {
    data(){
        return {
            show:false,
            repay:false,
            address:{}
        }
    },
    computed:{
        ...mapState(['addrlist'])
    },
    methods:{
        youjiwinning(){
            this.$ajax.winningordermail({
                addr_id:this.address.id,
                win_orderid:this.$route.query.win_orderid ,
                form_token:randomString(),
            }).then(res=>{
                if(res.code==200){
                    this.repay = false;
                    Notify({ type: 'success', message: res.msg });
                    this.$router.go(-1)
                }
            })
        },
        chooseAddress(item){
            if(this.$route.query.is_you){
                this.repay = true;
                this.address = item;
            }
        },
        ...mapMutations(['setaddrlist']),
        addAddress(){
            this.$router.push({
                name:'mine-address-add'
            })
        },
        delOneAddr(item){
            this.$ajax.deladdr({
                id:item.id
            }).then(res=>{
                if(res.code==200){
                    var addrlist = this.addrlist.filter(it=>it.id!==item.id)
                    this.setaddrlist(addrlist)
                }
            })
        },
        resetAddress(item){
            this.$router.push({
                name:'mine-address-reset',
                query:{
                    aid:item.id
                }
            })
        }
    },
    mounted(){
        // if(!this.addrlist.length>0){
            this.$ajax.addrlist()
            .then(res=>{
                if(res.code==200){
                    this.show  = true;
                    this.setaddrlist(res.data)
                    // this.addrlist = res.data
                }
            })
        // }else{
        //     this.show = true;
        // }
        
    }
}
</script>


